import { ErrorMessage } from '@hookform/error-message';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';

import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FormSection } from '../../features/form';
import {
  Upload,
  selectIsDocumentUploadInProgress,
} from '../../features/upload';
import { useClientFormContext } from '../ClientFormPage';
import { ClientFormPagePropType } from '../routes';

const FORM_ELEMENT_ID = 'documents.HEATING_STATEMENT';

export const HeatingStatementPage: React.FC<ClientFormPagePropType> = ({
  nextStep,
  previousStep,
}) => {
  const { t } = useTranslation();

  const { formId, clientId } = useClientFormContext();
  const { control, watch, trigger } = useFormContext();
  const isUploadInProgress = useSelector(selectIsDocumentUploadInProgress);
  const skipHeatingStatement = watch('skipHeatingStatement');
  const files = watch('documents.HEATING_STATEMENT');
  const isCheckboxDisabled = files.length > 0;
  const validateHeatingStatement = (files: unknown[]) => {
    return (
      (skipHeatingStatement && files.length === 0) ||
      (!skipHeatingStatement && files.length > 0) ||
      'heatingStatementRequired'
    );
  };

  const isFormValid = () => {
    if (isUploadInProgress) return Promise.resolve(false);
    return trigger(FORM_ELEMENT_ID);
  };

  return (
    <FormSection
      nextStep={nextStep}
      previousStep={previousStep}
      title={t('HEATING_STATEMENT_PAGE.title')}
      description={t('HEATING_STATEMENT_PAGE.description')}
      infoBoxChildren={
        <Trans i18nKey="documentUploadHint">
          Please make sure that the uploaded documents have:
          <ul>
            <li>enough light</li>
            <li>no blurry parts</li>
            <li>one page per picture</li>
            <li>pages in portrait orientation</li>
          </ul>
        </Trans>
      }
      isFormValid={isFormValid}
      disableNextButton={isUploadInProgress}
    >
      <FormControl data-id="upload-heatingStatement">
        <Upload
          documentType="HEATING_STATEMENT"
          clientId={clientId}
          formId={formId}
          rules={{
            validate: validateHeatingStatement,
          }}
        />
      </FormControl>
      {!isCheckboxDisabled && (
        <FormControl>
          <Controller
            name="skipHeatingStatement"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label={t('HEATING_STATEMENT_PAGE.skipHeatingStatement')}
              />
            )}
          />
        </FormControl>
      )}
      <FormHelperText error component="div">
        <ErrorMessage
          name={`${FORM_ELEMENT_ID}.root`}
          render={({ message }) => (
            <p>{t(`HEATING_STATEMENT_PAGE.${message}`)}</p>
          )}
          as="div"
        />
      </FormHelperText>
    </FormSection>
  );
};
