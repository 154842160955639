import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { AccountCircle } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { css } from '@emotion/react';
const rootStyles = css `
  display: flex;
`;
const iconWrapperStyles = css `
  position: relative;
`;
export const UserMenu = ({ avatar, userMenuHeadline, menuProps, menuItems = [], ...rest }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorEl(null);
    };
    return (_jsxs("div", { css: rootStyles, ...rest, children: [userMenuHeadline, _jsxs("div", { css: iconWrapperStyles, children: [_jsx(IconButton, { role: "button", "aria-controls": "menu-appbar", "aria-haspopup": "true", onClick: handleClick, color: "primary", children: avatar ?? _jsx(AccountCircle, { role: "img" }) }), _jsx(Menu, { anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                        }, keepMounted: true, transformOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        }, ...menuProps, anchorEl: anchorEl, open: open, onClose: closeMenu, children: menuItems.map((item) => (_jsx(MenuItem, { onClick: item.onClick, children: item.node }, item.key))) })] })] }));
};
