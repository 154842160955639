import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, useTheme, Stack, useMediaQuery, } from '@mui/material';
import { CheckCircleOutline, ErrorOutline, HelpOutline } from '@mui/icons-material';
import { css } from '@emotion/react';
const getAccentColor = (theme, variant) => {
    const accentColorMap = {
        error: theme.palette.error.main,
        warning: theme.palette.warning.main,
        success: theme.palette.primary.main,
        question: theme.palette.primary.main,
    };
    return accentColorMap[variant];
};
const getStyles = (theme, variant) => ({
    contentWrapper: css `
    padding: ${theme.spacing(4)} ${theme.spacing(3)};
  `,
    buttonWrapper: css `
    width: 100%;
    justify-content: flex-end;
  `,
    dialog: css `
    padding: 0;
  `,
    dialogAccent: css `
    background-color: ${getAccentColor(theme, variant)};
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
    dialogActions: css `
    padding: ${theme.spacing(2)} 0 !important;
  `,
    dialogActionsRoot: css `
    padding: 0 ${theme.spacing(3)};
  `,
    dialogTitle: css `
    padding: 0;
    align: center;
  `,
    dialogIcon: css `
    width: 100px;
    height: 100px;
  `,
    iconWrapper: css `
    background-color: white;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transform: translateY(25%);
  `,
});
const buttonColorMap = {
    error: 'error',
    warning: 'warning',
    success: 'success',
    question: 'success',
};
export const ConfirmationDialog = (props) => {
    const { onCancel, onConfirm, variant = 'success', dialogTitle, children, cancelText, confirmText, disableConfirmButton = false, ...rest } = props;
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(theme, variant);
    const dialogIconMap = {
        error: _jsx(ErrorOutline, { color: "error", css: styles.dialogIcon }),
        warning: _jsx(ErrorOutline, { color: "warning", css: styles.dialogIcon }),
        success: _jsx(CheckCircleOutline, { color: "secondary", css: styles.dialogIcon }),
        question: _jsx(HelpOutline, { color: "secondary", css: styles.dialogIcon }),
    };
    const direction = mobile ? 'column-reverse' : 'row';
    return (_jsxs(Dialog, { ...rest, children: [_jsxs(DialogContent, { css: styles.dialog, children: [_jsx("div", { css: styles.dialogAccent, children: _jsx("div", { css: styles.iconWrapper, children: dialogIconMap[variant] }) }), _jsxs(Stack, { spacing: 3, css: styles.contentWrapper, children: [dialogTitle && (_jsx(DialogTitle, { css: styles.dialogTitle, align: "center", children: dialogTitle })), children] })] }), _jsxs("div", { css: styles.dialogActionsRoot, children: [_jsx(Divider, { light: true }), _jsx(DialogActions, { css: styles.dialogActions, children: _jsxs(Stack, { spacing: 1, direction: direction, css: styles.buttonWrapper, children: [_jsx(Button, { variant: "text", color: "inherit", onClick: onCancel, children: cancelText }), _jsx(Button, { variant: "contained", color: buttonColorMap[variant], onClick: onConfirm, disabled: disableConfirmButton, children: confirmText })] }) })] })] }));
};
