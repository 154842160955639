import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { Avatar, Box, Divider, IconButton, LinearProgress, Stack, Typography, } from '@mui/material';
import { Clear, Error, Replay, Task, UploadFile } from '@mui/icons-material';
import { formatBytes } from '../../utils/formatBytes';
import { useIsScreenSize } from '../../hooks/useIsScreenSize';
const formatFileName = (fileName) => {
    const MAX_FILE_NAME_LENGTH = 15;
    if (fileName.length <= MAX_FILE_NAME_LENGTH)
        return fileName;
    const extension = fileName.split('.').pop();
    const extensionLength = extension ? extension.length + 1 : 0;
    return `${fileName.substring(0, MAX_FILE_NAME_LENGTH - extensionLength)}...${extension}`;
};
const LinearProgressWithLabel = (props) => {
    const mobile = useIsScreenSize();
    return (_jsxs(Stack, { direction: "row", alignItems: "center", spacing: 2, width: "100%", children: [!mobile && (_jsx(Box, { width: "100%", children: _jsx(LinearProgress, { ...props, color: "primary", variant: "determinate" }) })), _jsx(Box, { justifyContent: "flex-end", children: _jsx(Typography, { variant: "body2", color: "text.primary", children: `${Math.round(props.value)}%` }) })] }));
};
export const UploadItem = (props) => {
    const { error, fileName, fileSizeInBytes, onDelete, progress, showDivider = false, onRetry } = props;
    const mobile = useIsScreenSize();
    const handleDelete = () => onDelete();
    const handleRetry = () => onRetry();
    return (_jsxs(_Fragment, { children: [_jsxs(Stack, { spacing: 2, paddingY: 1, direction: "row", bgcolor: "#fff", alignItems: "center", children: [_jsx(Avatar, { sx: { bgcolor: 'transparent' }, children: error ? (_jsx(Error, { color: "error", "data-id": "errorIcon", fontSize: "large" })) : progress < 100 ? (_jsx(UploadFile, { color: "primary", "data-id": "progressIcon", fontSize: "large" })) : (_jsx(Task, { color: "primary", "data-id": "successIcon", fontSize: "large" })) }), _jsxs(Stack, { direction: "column", width: "100%", children: [_jsxs(Stack, { spacing: 1, direction: "row", children: [_jsx(Typography, { variant: "body2", children: !mobile ? fileName : formatFileName(fileName) }), fileSizeInBytes && (_jsx(Typography, { variant: "body2", color: "text.primary", children: formatBytes(fileSizeInBytes) }))] }), _jsx(Stack, { width: "100%", direction: "row", children: !error ? (_jsx(LinearProgressWithLabel, { value: progress, color: !error ? 'primary' : 'error' })) : (_jsx(Typography, { variant: "body2", color: "error", children: error })) })] }), !error && (_jsx(IconButton, { onClick: handleDelete, "data-id": "deleteButton", children: _jsx(Clear, {}) })), error && (_jsx(IconButton, { onClick: handleRetry, "data-id": "retryButton", children: _jsx(Replay, {}) }))] }), showDivider && _jsx(Divider, {})] }));
};
