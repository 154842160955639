import {
  DeliveryDatePage,
  HeatingStatementPage,
  NotePage,
  OperationalStatementPage,
  RentalContractPage,
  SummaryPage,
  WelcomePage,
} from './ClientForm';
import { SuccessPage } from './ClientForm/SuccessPage';

export const CLIENT_FORM_PAGES_MAP = {
  index: {
    index: true,
    Component: WelcomePage,
    nextStep: '1',
  },
  deliveryDate: {
    path: '1',
    Component: DeliveryDatePage,
    nextStep: '../2',
    previousStep: '..',
  },
  rentalContract: {
    path: '2',
    Component: RentalContractPage,
    nextStep: '../3',
    previousStep: '../1',
  },
  operationalStatement: {
    path: '3',
    Component: OperationalStatementPage,
    nextStep: '../4',
    previousStep: '../2',
  },
  heatingStatement: {
    path: '4',
    Component: HeatingStatementPage,
    nextStep: '../5',
    previousStep: '../3',
  },
  note: {
    path: '5',
    Component: NotePage,
    nextStep: '../6',
    previousStep: '../4',
  },
  summaryPage: {
    path: '6',
    Component: SummaryPage,
    previousStep: '../4',
  },
  success: {
    path: 'success',
    Component: SuccessPage,
  },
};

export type ClientFormPagePropType = {
  nextStep?: string;
  previousStep?: string;
};

type ClientFormPagesType = {
  index?: boolean;
  path?: string;
  Component: React.FC<ClientFormPagePropType>;
  nextStep?: string;
  previousStep?: string;
};

export const CLIENT_FORM_PAGES: ClientFormPagesType[] = Object.values(
  CLIENT_FORM_PAGES_MAP,
);
