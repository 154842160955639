import { css } from '@emotion/react';
import { EmojiObjects } from '@mui/icons-material';
import { Avatar, Theme, useTheme } from '@mui/material';
import React, { PropsWithChildren } from 'react';

const getStyles = (theme: Theme) => ({
  infoBox: css`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: ${theme.spacing(3)};
    width: 320px;
    background: ${theme.palette.extraColors.background.primaryLight};
    border-radius: 4px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    ${theme.breakpoints.down('md')} {
      border-radius: 0;
      width: 100%;
    }
  `,
  avatar: css`
    background-color: ${theme.palette.primary.main};
    width: 40px;
    height: 40px;
  `,
  icon: css`
    font-size: 30px;
  `,
});
export const InfoBox: React.FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  return (
    <div css={styles.infoBox}>
      <Avatar css={styles.avatar}>
        <EmojiObjects css={styles.icon} />
      </Avatar>
      {children}
    </div>
  );
};
