import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useRef, useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { css } from '@emotion/react';
// inspired by https://github.com/azmenak/material-ui-nested-menu-item
const secondaryContentStyles = css `
  display: flex;
  align-items: center;
`;
const submenuStyles = css `
  pointer-events: 'none';
`;
const submenuItemsContainerStyles = css `
  pointer-events: 'auto';
`;
export const NestedMenuItem = ({ label, children, dense, ...rest }) => {
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const menuItemRef = useRef(null);
    const containerRef = useRef(null);
    const handleSubMenuClose = () => {
        setIsSubMenuOpen(false);
    };
    const handleMouseEnter = () => {
        setIsSubMenuOpen(true);
    };
    const handleMouseLeave = () => {
        handleSubMenuClose();
    };
    const handleFocus = (event) => {
        if (event.target === containerRef.current) {
            setIsSubMenuOpen(true);
        }
    };
    return (_jsxs(MenuItem, { onMouseLeave: handleMouseLeave, onFocus: handleFocus, ...rest, children: [_jsx("div", { css: secondaryContentStyles, ref: menuItemRef, onMouseEnter: handleMouseEnter, children: label }), _jsx(Menu, { MenuListProps: { dense }, css: submenuStyles, anchorEl: menuItemRef.current, anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                }, open: isSubMenuOpen, disableAutoFocus: true, disableEnforceFocus: true, onMouseLeave: handleMouseLeave, onClose: handleSubMenuClose, children: _jsx("div", { ref: containerRef, css: submenuItemsContainerStyles, children: children }) })] }));
};
