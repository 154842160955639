import React, { useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRefreshTokenMutation } from '../../api';
import { ConfirmationDialog } from '@mineko/mineko-ui';
import { TokenContext } from '../../TokenContext';
import { Typography } from '@mui/material';

export const TokenExpiredDialog: React.FC = () => {
  const token = useContext(TokenContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [refreshToken, { data }] = useRefreshTokenMutation();

  useEffect(() => {
    const id = setInterval(() => {
      if (Date.now() >= token.exp * 1000) {
        setOpen(true);
      }
    }, 500);
    return () => clearInterval(id);
  }, [token.exp]);

  useEffect(() => {
    if (data) {
      navigate('../refresh-token/success');
    }
  });

  const handleRefreshExpiredToken = () => {
    refreshToken();
  };

  const navigateToRefreshTokenPage = () => {
    navigate(
      { pathname: '/refresh-token', search: location.search },
      { replace: true },
    );
  };

  return (
    <ConfirmationDialog
      open={open}
      dialogTitle={t('TOKEN_EXPIRED_DIALOG.title')!}
      variant="error"
      cancelText={t('TOKEN_EXPIRED_DIALOG.cancel')}
      confirmText={t('TOKEN_EXPIRED_DIALOG.confirm')}
      onConfirm={handleRefreshExpiredToken}
      onCancel={navigateToRefreshTokenPage}
    >
      <Typography variant="body1">
        {t('TOKEN_EXPIRED_DIALOG.description')}
      </Typography>
    </ConfirmationDialog>
  );
};
