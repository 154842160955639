import { createTheme } from '@mui/material/styles';
export const palette = {
    primary: {
        main: '#178C70',
        light: '#1BAC8A',
        dark: '#13775F',
        contrastText: '#fff',
    },
    secondary: {
        main: '#67AE48',
        light: '#6EB64F',
        dark: '#61A344',
        contrastText: '#fff',
    },
    success: {
        main: '#178C70',
        light: '#1BAC8A',
        dark: '#13775F',
        contrastText: '#fff',
    },
    background: {
        default: '#fff',
    },
    text: {
        primary: '#383839',
        secondary: '#696969',
    },
    info: {
        main: '#1580B0',
        light: '#1999D2',
        dark: '#0E6186',
        contrastText: '#fff',
    },
    warning: {
        main: '#FF8A02',
        light: '#FF9D2E',
        dark: '#D67200',
        contrastText: '#fff',
    },
    error: {
        main: '#C62B1F',
        light: '#C64E4E',
        dark: '#B51C1C',
        contrastText: '#fff',
    },
    extraColors: {
        background: {
            primaryLight: '#F1F8F7',
            primaryDark: '#E0EBE4',
            error: '#FFE9E9',
            warningLight: '#FDF0E1',
            warningDark: '#FADBB7',
            success: '#EAF4F4',
            info: '#ECF7FF',
        },
        border: {
            error: '#F0415A',
            info: '#1580B0',
            success: '#6EC7B5',
            warning: '#FF8A02',
        },
        greyscale: {
            light: '#EEEEEE',
            main: '888888',
            dark: '616161',
        },
        headline: {
            error: '#B51C1C',
            info: '#3665C4',
            success: '#13775F',
            warning: '#93370D',
        },
    },
};
const getPaletteOptions = () => palette;
const options = {
    palette,
    mixins: {
        toolbar: {
            height: '70px',
        },
    },
    typography: {
        fontFamily: 'Open Sans, sans-serif',
        htmlFontSize: 16,
        fontSize: 14,
        allVariants: {
            letterSpacing: '-0.3px',
        },
        body1: {
            lineHeight: '170%',
        },
        body2: {
            lineHeight: '190%',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    fontSize: 15,
                    letterSpacing: '0.46px',
                },
            },
            defaultProps: {
                disableRipple: true,
                disableElevation: true,
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    color: getPaletteOptions().text.primary,
                    fontWeight: 600,
                    lineHeight: 1.5,
                    fontSize: 24,
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                rounded: {
                    borderRadius: '37%',
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: `
        @font-face {
          font-family: 'Open Sans';
          font-style: normal;
          font-display: swap;
          src: url('https://static.mineko.de/fonts/OpenSans.ttf') format('truetype-variations');
        }
        html {
          margin: 0;
          padding: 0;
        }
        body {
          margin: 0;
          padding: 0;
          font-size: 16px;
          color: ${getPaletteOptions().text.primary};
        }
        #root {
          height: 100%;
        }
        a {
          color: ${getPaletteOptions().primary.main};
        },
        input:-webkit-autofill {
          transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
        }
        input, select, textarea {
          font-size: 16px;
        }
        input[type=date] {
          cursor: pointer;
        }
        input[type=date]::-webkit-calendar-picker-indicator {
          cursor: pointer;
        }
      `,
        },
        MuiTextField: {
            variants: [
                {
                    props: { variant: 'filled', color: 'primary', disabled: false },
                    style: {
                        '& .MuiFilledInput-root': {
                            backgroundColor: `${getPaletteOptions().primary.main}0f`,
                        },
                        '& .MuiFilledInput-root.Mui-focused': {
                            backgroundColor: `${getPaletteOptions().primary.main}1a`,
                        },
                        '&:hover:not(.Mui-disabled)': {
                            backgroundColor: `${getPaletteOptions().primary.main}1f`,
                        },
                    },
                },
                {
                    props: { variant: 'filled', color: 'secondary', disabled: false },
                    style: {
                        '& .MuiFilledInput-root': {
                            backgroundColor: `${getPaletteOptions().extraColors.background.primaryLight}`,
                        },
                        '& .MuiFilledInput-root.Mui-focused': {
                            backgroundColor: `${getPaletteOptions().secondary.main}1a`,
                        },
                        '&:hover:not(.Mui-disabled)': {
                            backgroundColor: `${getPaletteOptions().extraColors.background.primaryDark}`,
                        },
                    },
                },
            ],
        },
    },
};
export const theme = createTheme(options);
