import React from 'react';
import { Aside, Root } from './Layout';
import { Header } from '@mineko/mineko-ui';
import { useTranslation } from 'react-i18next';
import { Container, Stack, Theme, Typography, useTheme } from '@mui/material';
import { SentimentVerySatisfiedOutlined } from '@mui/icons-material';

import { css } from '@emotion/react';

const getStyles = (theme: Theme) => ({
  content: css`
    height: 100%;
    align-items: center;
    justify-content: center;
  `,
  headline: css`
    margin-bottom: ${theme.spacing(2)};
  `,
  icon: css`
    fill: ${theme.palette.primary.light}69;
    height: 180px;
    width: 180px;
  `,
  container: css`
    height: calc(100vh - 70px - 40px);
  `,
  footer: css`
    display: flex;
    flex-direction: row-reverse;
    a {
      margin-right: ${theme.spacing(3)};
    }
  `,
  success: css`
    height: 172px;
  `,
});

export const RefreshTokenSuccessPage: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <Root>
      <Header>{}</Header>
      <Container css={styles.container}>
        <Stack css={styles.content} spacing={3}>
          <>
            <SentimentVerySatisfiedOutlined css={styles.icon} />
            <div css={styles.success}>
              <Typography variant="h4" align="center" css={styles.headline}>
                {t('TOKEN_REFRESH_PAGE.titleSuccess')}
              </Typography>
              <Typography variant="body1" align="center">
                {t('TOKEN_REFRESH_PAGE.descriptionSuccess')}
              </Typography>
              <Typography variant="body1" align="center">
                {t('TOKEN_REFRESH_PAGE.callToActionSuccess')}
              </Typography>
            </div>
          </>
        </Stack>
      </Container>
      <Aside />
    </Root>
  );
};
