import { CloudUpload, Event, ThumbUp } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { InfoBox } from '../../features/info-box';
import { STEP_SPACING, Step } from '../../features/step';
import {
  Aside,
  Content,
  Footer,
  Main,
  Subtitle,
  Title,
  Wrapper,
} from '../Layout';
import { useStepNavigation } from './useStepNavigation';
import { useClientFormQuery } from '../../api';
import { ClientFormPagePropType } from '../routes';
import { useTranslation } from 'react-i18next';

const steps = [
  {
    label: 'stepDeliveryDate',
    icon: Event,
  },
  {
    label: 'stepUploadDocuments',
    icon: CloudUpload,
  },
  {
    label: 'stepStartAndWait',
    icon: ThumbUp,
  },
];

export const WelcomePage: React.FC<ClientFormPagePropType> = ({ nextStep }) => {
  const { t } = useTranslation();
  const { data } = useClientFormQuery();

  const { handleNextStep } = useStepNavigation({
    nextStep,
  });

  const showGreating = data?.ClientForm.firstName && data.ClientForm.lastName;
  const showReference = !!data?.ClientForm.reference;

  return (
    <Wrapper>
      <Main>
        <Content>
          <Title>{t('WELCOME_PAGE.title')}</Title>
          <Subtitle>
            <>
              {showGreating &&
                `${t('WELCOME_PAGE.greating', {
                  firstName: data.ClientForm.firstName,
                  lastName: data.ClientForm.lastName,
                })} `}
              {t('WELCOME_PAGE.description')}
              {showReference &&
                ` ${t('WELCOME_PAGE.referenceNumber', {
                  reference: data.ClientForm.reference,
                })}`}
            </>
          </Subtitle>
          <Stack spacing={STEP_SPACING}>
            {steps.map((step, index) => (
              <Step
                key={index}
                Icon={step.icon}
                label={t(`WELCOME_PAGE.${step.label}`)}
              />
            ))}
          </Stack>
        </Content>
        <Footer>
          <div>{/* spacer */}</div>
          <Button variant="contained" onClick={handleNextStep}>
            {t('WELCOME_PAGE.startNow')}
          </Button>
        </Footer>
      </Main>
      <Aside>
        <InfoBox>
          <Box my={1}>
            <Typography variant="body1">
              <strong>{t('hint')}</strong>
            </Typography>
          </Box>
          <Typography variant="body1">{t('WELCOME_PAGE.hint')}</Typography>
        </InfoBox>
      </Aside>
    </Wrapper>
  );
};
