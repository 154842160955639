import { ApolloProvider } from '@apollo/client';
import { BasicTheme } from '@mineko/mineko-ui';
import { useConfig, useQueryParams } from '@mineko/react-toolkit';
import { ErrorType, SentryInit } from '@mineko/ui-core';
import { SnackbarProvider } from 'notistack';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { client } from './api';
import { GeneralError } from './features/error';
import { CLIENT_FORM_PAGES, ClientFormPage } from './pages';
import { CheckResultPage } from './pages/CheckResultPage';
import { RefreshToken } from './pages/RefreshToken';
import { createStore } from './store';
import decodeJWT from 'jwt-decode';
import { JwtToken, TokenContext } from './TokenContext';
import { RefreshTokenSuccessPage } from './pages/RefreshTokenSuccessPage';

export const Entrypoint: React.FC = () => {
  const config = useConfig();
  const { token } = useQueryParams<{ token: string }>();
  useEffect(() => {
    if (config) {
      console.info(`Config loaded: ${config.APP.REACT_APP_ENV}`);
      config.APP.REACT_APP_SENTRY_DSN &&
        SentryInit({
          dsn: config.APP.REACT_APP_SENTRY_DSN,
          release: (window as { [key: string]: any })['VERSION'],
          environment: config.APP.REACT_APP_ENV,
        });
    }
  }, [config]);

  if (!token) return <GeneralError />;

  const decodedJWT = decodeJWT<JwtToken>(token);

  return (
    config && (
      <BasicTheme>
        <SnackbarProvider maxSnack={5}>
          <ApolloProvider client={client(config.APP.REACT_APP_API_URL, token)}>
            <Provider store={createStore()}>
              <TokenContext.Provider value={decodedJWT}>
                <Router basename={process.env['PUBLIC_URL']}>
                  <Routes>
                    <Route path="/pruefen-lassen" element={<ClientFormPage />}>
                      {CLIENT_FORM_PAGES.map(
                        (
                          { index, path, Component, nextStep, previousStep },
                          key,
                        ) => (
                          <Route
                            key={key}
                            index={index}
                            path={path}
                            element={
                              <Component
                                nextStep={nextStep}
                                previousStep={previousStep}
                              />
                            }
                          />
                        ),
                      )}
                    </Route>
                    <Route
                      path="/refresh-token"
                      element={<RefreshToken />}
                    ></Route>
                    <Route
                      path="/refresh-token/success"
                      element={<RefreshTokenSuccessPage />}
                    ></Route>
                    <Route
                      path="/pruefungsergebnis"
                      element={<CheckResultPage />}
                    />
                  </Routes>
                </Router>
              </TokenContext.Provider>
            </Provider>
          </ApolloProvider>
        </SnackbarProvider>
      </BasicTheme>
    )
  );
};
