import { css } from '@emotion/react';
import { LinearProgress, Theme, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CLIENT_FORM_PAGES } from '../../pages';

const getStyles = (theme: Theme) => ({
  root: css`
    position: sticky;
    top: calc(${theme.mixins.toolbar.height} + 2px);
    display: block;
    z-index: ${theme.zIndex.appBar};
  `,
});

export const Progress: React.FC = () => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const { pathname } = useLocation();
  const currentIndex = CLIENT_FORM_PAGES.findIndex(
    ({ path }) => pathname === `/pruefen-lassen/${path}`,
  );

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
  }, [pathname]);

  const progress = (currentIndex / (CLIENT_FORM_PAGES.length - 1)) * 100;
  return (
    <LinearProgress
      css={styles.root}
      variant="determinate"
      value={progress}
      color="primary"
      sx={{ height: '8px' }}
    />
  );
};
