import { css } from '@emotion/react';
import { UploadedFileType } from '@mineko/mineko-ui/features';
import { ErrorMessage } from '@hookform/error-message';
import { Theme } from '@mineko/mineko-ui/theme/mineko.theme';
import {
  Comment,
  Description,
  Edit,
  Event,
  SvgIconComponent,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { InfoBox } from '../../features/info-box';
import {
  Aside,
  Content,
  Footer,
  Main,
  Subtitle,
  Title,
  Wrapper,
} from '../Layout';
import { useStepNavigation } from './useStepNavigation';
import { ClientFormPagePropType } from '../routes';

type ItemsType =
  | 'deliveryDate'
  | 'rentalContract'
  | 'operationalStatement'
  | 'heatingStatement'
  | 'note';
const ITEMS: ItemsType[] = [
  'deliveryDate',
  'rentalContract',
  'operationalStatement',
  'heatingStatement',
  'note',
];

const ICON_MAP: Record<ItemsType, SvgIconComponent> = {
  deliveryDate: Event,
  rentalContract: Description,
  operationalStatement: Description,
  heatingStatement: Description,
  note: Comment,
};

const getStyles = (theme: Theme) => ({
  textfield: css`
    max-width: 220px;
  `,
  summaryItem: (hasError: boolean) => css`
    background-color: ${theme.palette.grey[100]};
    border-bottom: 1px solid ${theme.palette.grey[300]};
    &:last-child {
      border-bottom: none;
    }

    ${hasError &&
    css`
      background-color: ${theme.palette.error.light};
      border-bottom: 1px solid ${theme.palette.error.main};
    `}
  `,
  checkboxRoot: css`
    margin-top: ${theme.spacing(2)};
  `,
  checkbox: css`
    margin-bottom: ${theme.spacing(3)};
  `,
});

const SummaryItem: React.FC<
  PropsWithChildren & {
    Icon: SvgIconComponent;
    title: string;
    onEdit: () => void;
    hasError: boolean;
  }
> = ({ Icon, title, onEdit, hasError, children }) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <ListItem
      css={styles.summaryItem(hasError)}
      dense={false}
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="edit"
          color="primary"
          onClick={onEdit}
        >
          <Edit />
        </IconButton>
      }
    >
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={title} secondary={children || '-'} />
    </ListItem>
  );
};

const MAX_FILENAMES_TO_SHOW = 4;
const renderDocuments = (documents: UploadedFileType[]) => {
  const fileNames = documents.map(({ fileName }) => fileName);
  if (fileNames.length > MAX_FILENAMES_TO_SHOW)
    fileNames.splice(
      MAX_FILENAMES_TO_SHOW,
      fileNames.length - MAX_FILENAMES_TO_SHOW,
      '...',
    );
  return fileNames.join(', ');
};

const idToDocumentTypeMap = {
  rentalContract: 'RENTAL_CONTRACT',
  operationalStatement: 'OPERATIONAL_STATEMENT',
  heatingStatement: 'HEATING_STATEMENT',
};

const FORM_ELEMENT_ID = 'acceptAgb';

export const SummaryPage: React.FC<ClientFormPagePropType> = ({
  nextStep,
  previousStep,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);
  const { goToStep } = useStepNavigation({
    nextStep,
    previousStep,
  });

  const { getValues, formState } = useFormContext();

  const handleEdit = (step: ItemsType) => () => {
    goToStep(step);
  };

  const errorMessageRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (errorMessageRef.current && formState.errors[FORM_ELEMENT_ID]) {
      errorMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [formState]);

  const getItemValue = (id: ItemsType) => {
    if (id === 'deliveryDate') {
      const deliveryDate = getValues('deliveryDate');
      if (!deliveryDate) return;
      return new Date(getValues('deliveryDate')).toLocaleDateString();
    }
    if (id === 'note') {
      const note = getValues('note');
      return note;
    }
    if (id === 'heatingStatement') {
      const documents = getValues(`documents.${idToDocumentTypeMap[id]}`);
      const skip = getValues('skipHeatingStatement');
      if (skip && !documents.length) return t('skipHeatingStatement');
    }
    if (
      ['rentalContract', 'operationalStatement', 'heatingStatement'].includes(
        id,
      )
    ) {
      const documents = getValues(`documents.${idToDocumentTypeMap[id]}`);
      return renderDocuments(documents);
    }
  };

  const isValidItem = (id: ItemsType) => {
    if (id === 'note') return true;
    if (id === 'deliveryDate') {
      const deliveryDate = getValues('deliveryDate');
      return !!deliveryDate;
    }
    if (['rentalContract', 'operationalStatement'].includes(id)) {
      const documents = getValues(`documents.${idToDocumentTypeMap[id]}`);
      return !!documents.length;
    }
    if (id === 'heatingStatement') {
      const documents = getValues(`documents.${idToDocumentTypeMap[id]}`);
      const skip = getValues('skipHeatingStatement');
      return skip || !!documents.length;
    }
    return true;
  };

  const disableSubmit = ITEMS.some((id) => !isValidItem(id));

  return (
    <Wrapper>
      <Main>
        <Content>
          <Title>{t('SUMMARY_PAGE.title')}</Title>
          <Subtitle>{t('SUMMARY_PAGE.description')}</Subtitle>
          <List>
            {ITEMS.map((id) => (
              <SummaryItem
                key={id}
                Icon={ICON_MAP[id]}
                title={t(id)}
                onEdit={handleEdit(id)}
                hasError={!isValidItem(id)}
              >
                {getItemValue(id)}
              </SummaryItem>
            ))}
          </List>
          <FormControlLabel
            css={styles.checkboxRoot}
            control={
              <Controller
                name={FORM_ELEMENT_ID}
                rules={{ required: true }}
                render={({ field }) => (
                  <Checkbox {...field} css={styles.checkbox} />
                )}
              />
            }
            label={
              <>
                <Trans i18nKey="SUMMARY_PAGE.acceptAgb">
                  I read the
                  <a
                    target="_blank"
                    href="https://www.mineko.de/agb"
                    rel="noreferrer"
                  >
                    AGB
                  </a>
                  and
                  <a
                    target="_blank"
                    href="https://www.mineko.de/datenschutz"
                    rel="noreferrer"
                  >
                    privacy policy
                  </a>
                  of Mineko GmbH and accept them.
                </Trans>
              </>
            }
          />
          <FormHelperText error component="div">
            <ErrorMessage
              as="div"
              name={FORM_ELEMENT_ID}
              render={() => (
                <p ref={errorMessageRef}>
                  {t('SUMMARY_PAGE.acceptAgbRequired')}
                </p>
              )}
            />
          </FormHelperText>
        </Content>
        <Footer>
          <div>{/* spacer */}</div>
          <Button variant="contained" type="submit" disabled={disableSubmit}>
            {t('SUMMARY_PAGE.confirm')}
          </Button>
        </Footer>
      </Main>
      <Aside>
        <InfoBox>
          <Box my={1}>
            <Typography variant="body1">
              <strong>{t('hint')}</strong>
            </Typography>
          </Box>
          <Typography variant="body1">
            <Trans i18nKey="SUMMARY_PAGE.hint">
              By clicking <Edit fontSize="small" color="primary" /> you can edit
              your data.
            </Trans>
          </Typography>
        </InfoBox>
      </Aside>
    </Wrapper>
  );
};
