import React from 'react';
import { createRoot } from 'react-dom/client';
import './i18n';
import { ConfigProvider } from '@mineko/react-toolkit';
import { SentryErrorBoundary } from '@mineko/ui-core';
import { Entrypoint } from './Entrypoint';
import { init } from '../hotjar';

console.info('Version:', (window as { [key: string]: any })['VERSION']);
if (window.location.hostname === 'upload.mineko.de') {
  console.info('Init HOTJAR');
  init();
}

const configs = [
  { name: 'APP', url: `${process.env['PUBLIC_URL']}config.json` },
];
const Root: React.FC = () => (
  <SentryErrorBoundary
    fallback={({ error }) => (
      <details>
        {error?.name}
        <br />
        {error?.message}
        <br />
        {error?.stack}
        <br />
      </details>
    )}
  >
    <ConfigProvider items={configs}>
      <Entrypoint />
    </ConfigProvider>
  </SentryErrorBoundary>
);
export const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(<Root />);
