import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { UploadArea, UploadItem } from '../../.';
import { List } from '@mui/material';
import { useEffect, useState } from 'react';
const ACCEPT_FILES = {
    'image/jpeg': [],
    'image/png': [],
    'image/heif-sequence': [],
    'image/heic': [],
    'image/heic-sequence': [],
    'application/pdf': [],
};
const DocumentUploadItem = ({ file, id: initialId, fileName, showDivider, onFileUploadRequired, onUploadFile, onFileUploadSuccess, onFileDelete, }) => {
    const [progress, setProgress] = useState(initialId ? 100 : 0);
    const [error, setError] = useState('');
    const [id, setId] = useState(initialId);
    const [url, setUrl] = useState();
    const [isDeleted, setIsDeleted] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const [isUploadCompleted, setIsUploadCompleted] = useState(false);
    const onUploadProgress = ({ progress }) => {
        progress && setProgress(progress * 100);
    };
    const handleFileDelete = () => {
        setIsDeleted(true);
        id && onFileDelete(id);
    };
    useEffect(() => {
        if (id || !file?.name)
            return;
        onFileUploadRequired(file.name).then(({ url, id }) => {
            setId(id);
            setUrl(url);
        });
    }, [file?.name, onFileUploadRequired, id]);
    const handleFileUpload = () => {
        if (!url || !id || !file)
            return;
        onUploadFile({ url, file, onUploadProgress })
            .then(() => {
            setIsUploaded(true);
        })
            .catch((error) => {
            console.error(error.message);
            setError('Netzwerk Fehler: Bitte versuchen Sie es erneut.');
        });
    };
    const handleRetry = () => {
        setError('');
        setProgress(0);
        handleFileUpload();
    };
    useEffect(() => {
        handleFileUpload();
    }, [url, id, file]);
    useEffect(() => {
        if (isUploadCompleted || !id || !isUploaded || isDeleted || !file)
            return;
        onFileUploadSuccess({ id, fileName: file.name });
        setIsUploadCompleted(true);
    }, [isUploadCompleted, isUploaded, isDeleted, id, file?.name, onFileUploadSuccess]);
    if (isDeleted)
        return null;
    return (_jsx(UploadItem, { fileName: fileName, fileSizeInBytes: file?.size, onDelete: handleFileDelete, progress: progress, error: error, onRetry: handleRetry, showDivider: showDivider }));
};
export const DocumentUpload = ({ onFileUploadRequired, onFileUploadSuccess, onUploadFile, onFileDelete, initialFiles, texts, }) => {
    const [files, setFiles] = useState(initialFiles ?? []);
    const onDrop = (newFiles) => {
        const mappedFiles = newFiles.map((file) => ({
            fileName: file.name,
            rawFile: file,
        }));
        setFiles((f) => [...f, ...mappedFiles]);
    };
    return (_jsxs(_Fragment, { children: [_jsx(UploadArea, { AcceptFiles: ACCEPT_FILES, onDrop: onDrop, acceptFilesText: texts.acceptFiles, chooseFilesText: texts.chooseFiles, dropFilesText: texts.dropFiles, mobileText: texts.mobileText, rejectFilesText: texts.rejectFiles }), _jsx(List, { children: files.map((file, index) => (_jsx(DocumentUploadItem, { id: file.id, fileName: file.fileName, file: file.rawFile, showDivider: index !== files.length - 1, onFileUploadRequired: onFileUploadRequired, onFileUploadSuccess: onFileUploadSuccess, onFileDelete: onFileDelete, onUploadFile: onUploadFile }, index))) })] }));
};
