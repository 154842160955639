import { configureStore, StateFromReducersMapObject } from '@reduxjs/toolkit';
import { reducer as uploadReducer } from '../features/upload';
export const reducer = { upload: uploadReducer };
export type AppStateType = StateFromReducersMapObject<typeof reducer>;
export const createStore = () => {
  const store = configureStore({
    reducer,
  });

  return store;
};
