import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type CheckResultDocumentType = {
  __typename?: 'CheckResultDocument';
  type: CheckResultDocumentTypeType;
  url: Scalars['String'];
};

export type CheckResultDocumentTypeType =
  | 'CHECK_REPORT'
  | 'CONTRADICTION_LETTER'
  | 'EXTRAJUDICIAL_LETTER';

export type ClientFormType = {
  __typename?: 'ClientForm';
  checkResultDocuments?: Maybe<Array<CheckResultDocumentType>>;
  clientId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  formId: Scalars['String'];
  lastName: Scalars['String'];
  productId: Scalars['String'];
  reference: Scalars['String'];
  submittedAt?: Maybe<Scalars['String']>;
};

export type DocumentInputType = {
  files: Array<FilesInputType>;
  type: DocumentTypeEnumType;
};

export type DocumentTypeEnumType =
  | 'HEATING_STATEMENT'
  | 'OPERATIONAL_STATEMENT'
  | 'RENTAL_CONTRACT';

export type FilesInputType = {
  fileName: Scalars['String'];
  id: Scalars['String'];
};

export type MutationType = {
  __typename?: 'Mutation';
  PrepareUpload: PrepareUploadResponseType;
  RefreshToken: Scalars['Boolean'];
  SubmitClientForm: Scalars['Boolean'];
};

export type MutationPrepareUploadArgsType = {
  input: PrepareUploadInputType;
};

export type MutationSubmitClientFormArgsType = {
  input: SubmitClientFormInputType;
};

export type PrepareUploadInputType = {
  clientId: Scalars['String'];
  documentType: DocumentTypeEnumType;
  fileName: Scalars['String'];
  formId: Scalars['String'];
};

export type PrepareUploadResponseType = {
  __typename?: 'PrepareUploadResponse';
  fileId: Scalars['String'];
  uploadUrl: Scalars['String'];
};

export type QueryType = {
  __typename?: 'Query';
  ClientForm: ClientFormType;
};

export type SubmitClientFormInputType = {
  clientId: Scalars['String'];
  deliveryDate: Scalars['String'];
  documents: Array<DocumentInputType>;
  formId: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
};

export type SubmitClientFormMutationVariablesType = Exact<{
  input: SubmitClientFormInputType;
}>;

export type SubmitClientFormMutationType = {
  __typename?: 'Mutation';
  SubmitClientForm: boolean;
};

export type PrepareUploadMutationVariablesType = Exact<{
  input: PrepareUploadInputType;
}>;

export type PrepareUploadMutationType = {
  __typename?: 'Mutation';
  PrepareUpload: {
    __typename?: 'PrepareUploadResponse';
    uploadUrl: string;
    fileId: string;
  };
};

export type RefreshTokenMutationVariablesType = Exact<{ [key: string]: never }>;

export type RefreshTokenMutationType = {
  __typename?: 'Mutation';
  RefreshToken: boolean;
};

export type ClientFormQueryVariablesType = Exact<{ [key: string]: never }>;

export type ClientFormQueryType = {
  __typename?: 'Query';
  ClientForm: {
    __typename?: 'ClientForm';
    clientId: string;
    formId: string;
    reference: string;
    firstName: string;
    lastName: string;
    productId: string;
    submittedAt?: string | null;
    checkResultDocuments?: Array<{
      __typename?: 'CheckResultDocument';
      type: CheckResultDocumentTypeType;
      url: string;
    }> | null;
  };
};

export const SubmitClientFormDocumentType = gql`
  mutation SubmitClientForm($input: SubmitClientFormInput!) {
    SubmitClientForm(input: $input)
  }
`;
export type SubmitClientFormMutationFnType = Apollo.MutationFunction<
  SubmitClientFormMutationType,
  SubmitClientFormMutationVariablesType
>;

/**
 * __useSubmitClientFormMutation__
 *
 * To run a mutation, you first call `useSubmitClientFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitClientFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitClientFormMutation, { data, loading, error }] = useSubmitClientFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitClientFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitClientFormMutationType,
    SubmitClientFormMutationVariablesType
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitClientFormMutationType,
    SubmitClientFormMutationVariablesType
  >(SubmitClientFormDocumentType, options);
}
export type SubmitClientFormMutationHookResult = ReturnType<
  typeof useSubmitClientFormMutation
>;
export type SubmitClientFormMutationResultType =
  Apollo.MutationResult<SubmitClientFormMutationType>;
export type SubmitClientFormMutationOptionsType = Apollo.BaseMutationOptions<
  SubmitClientFormMutationType,
  SubmitClientFormMutationVariablesType
>;
export const PrepareUploadDocumentType = gql`
  mutation PrepareUpload($input: PrepareUploadInput!) {
    PrepareUpload(input: $input) {
      uploadUrl
      fileId
    }
  }
`;
export type PrepareUploadMutationFnType = Apollo.MutationFunction<
  PrepareUploadMutationType,
  PrepareUploadMutationVariablesType
>;

/**
 * __usePrepareUploadMutation__
 *
 * To run a mutation, you first call `usePrepareUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrepareUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prepareUploadMutation, { data, loading, error }] = usePrepareUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrepareUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PrepareUploadMutationType,
    PrepareUploadMutationVariablesType
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PrepareUploadMutationType,
    PrepareUploadMutationVariablesType
  >(PrepareUploadDocumentType, options);
}
export type PrepareUploadMutationHookResult = ReturnType<
  typeof usePrepareUploadMutation
>;
export type PrepareUploadMutationResultType =
  Apollo.MutationResult<PrepareUploadMutationType>;
export type PrepareUploadMutationOptionsType = Apollo.BaseMutationOptions<
  PrepareUploadMutationType,
  PrepareUploadMutationVariablesType
>;
export const RefreshTokenDocumentType = gql`
  mutation RefreshToken {
    RefreshToken
  }
`;
export type RefreshTokenMutationFnType = Apollo.MutationFunction<
  RefreshTokenMutationType,
  RefreshTokenMutationVariablesType
>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshTokenMutationType,
    RefreshTokenMutationVariablesType
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshTokenMutationType,
    RefreshTokenMutationVariablesType
  >(RefreshTokenDocumentType, options);
}
export type RefreshTokenMutationHookResult = ReturnType<
  typeof useRefreshTokenMutation
>;
export type RefreshTokenMutationResultType =
  Apollo.MutationResult<RefreshTokenMutationType>;
export type RefreshTokenMutationOptionsType = Apollo.BaseMutationOptions<
  RefreshTokenMutationType,
  RefreshTokenMutationVariablesType
>;
export const ClientFormDocumentType = gql`
  query ClientForm {
    ClientForm {
      clientId
      formId
      reference
      firstName
      lastName
      productId
      submittedAt
      checkResultDocuments {
        type
        url
      }
    }
  }
`;

/**
 * __useClientFormQuery__
 *
 * To run a query within a React component, call `useClientFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientFormQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ClientFormQueryType,
    ClientFormQueryVariablesType
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClientFormQueryType, ClientFormQueryVariablesType>(
    ClientFormDocumentType,
    options,
  );
}
export function useClientFormLazyQueryType(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientFormQueryType,
    ClientFormQueryVariablesType
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClientFormQueryType, ClientFormQueryVariablesType>(
    ClientFormDocumentType,
    options,
  );
}
export type ClientFormQueryHookResult = ReturnType<typeof useClientFormQuery>;
export type ClientFormLazyQueryTypeHookResult = ReturnType<
  typeof useClientFormLazyQueryType
>;
export type ClientFormQueryResultType = Apollo.QueryResult<
  ClientFormQueryType,
  ClientFormQueryVariablesType
>;
