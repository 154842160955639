import { InMemoryCache } from '@apollo/client';
import { initializeClient } from '@mineko/ui-core';

export const client = (url: string, token: string) =>
  initializeClient({
    url,
    authOptions: {
      type: 'AWS_LAMBDA',
      token,
    },
    cache: new InMemoryCache(),
  });
