import { ConfirmationDialog, Header, LoadingOverlay } from '@mineko/mineko-ui';
import { UploadedFileType } from '@mineko/mineko-ui/features';
import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import {
  DocumentTypeEnumType,
  useClientFormQuery,
  useSubmitClientFormMutation,
} from '../api';
import { Progress } from '../features/navigation';

import { GeneralError } from '../features/error';
import { Logo } from '../features/header';
import { Root } from './Layout';
import { ServerError } from '@apollo/client';

import { TokenExpiredDialog } from '../features/token-expired-dialog';

type ContextType = { clientId: string; formId: string };

export function useClientFormContext() {
  return useOutletContext<ContextType>();
}

export type FormStateType = {
  deliveryDate: string;
  documents: Record<DocumentTypeEnumType, UploadedFileType[]>;
  note: string;
  skipHeatingStatement: boolean;
  acceptAgb: boolean;
};

export const ClientFormPage: React.FC = () => {
  const { data, loading, error } = useClientFormQuery();
  const [submitClientForm, { loading: submitLoading, error: submitError }] =
    useSubmitClientFormMutation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // A Network Error would contain the Authentication Error for an expired jwt token
    // The network error message does not reflect the error message returned by the server
    // but it the correct message does exist inside the nested result object

    if (error && error.networkError) {
      const networkError = error?.networkError as ServerError;
      const clientFormError = networkError.result?.errors[0];

      if (clientFormError?.message === 'jwt expired') {
        navigate(
          { pathname: '/refresh-token', search: location.search },
          { replace: true },
        );
      }
    }
  }, [error, location.search, navigate]);

  const methods = useForm<FormStateType>({
    defaultValues: {
      deliveryDate: '',
      documents: {
        RENTAL_CONTRACT: [],
        OPERATIONAL_STATEMENT: [],
        HEATING_STATEMENT: [],
      },
      note: '',
      skipHeatingStatement: false,
      acceptAgb: false,
    },
    mode: 'onChange',
  });

  const navigateToSuccess = useCallback(() => {
    navigate(
      { pathname: 'success', search: location.search },
      { replace: true },
    );
  }, [navigate]);

  const navigateToCheckResult = useCallback(() => {
    navigate(
      { pathname: '../pruefungsergebnis/', search: location.search },
      { replace: true },
    );
  }, [navigate]);

  useEffect(() => {
    const isSuccessPage = location.pathname.includes('success');
    const isCheckResultPage = location.pathname.includes('pruefungsergebnis');
    if (!isCheckResultPage && data?.ClientForm.checkResultDocuments) {
      navigateToCheckResult();
      return;
    }
    if (!isSuccessPage && data?.ClientForm.submittedAt) {
      navigateToSuccess();
      return;
    }
  }, [data, navigateToSuccess, navigateToCheckResult, location.pathname]);

  const onSubmit = (formData: FormStateType) => {
    if (!data) return;
    submitClientForm({
      variables: {
        input: {
          clientId: data.ClientForm.clientId,
          formId: data.ClientForm.formId,
          deliveryDate: formData.deliveryDate,
          note: formData.note || null,
          documents: Object.entries(formData.documents)
            .filter(([, files]) => files.length > 0)
            .map(([type, files]) => ({
              type: type as DocumentTypeEnumType,
              files,
            })),
        },
      },
    }).then(() => {
      methods.reset();
      navigateToSuccess();
    });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Root>
            <Header>
              {data && <Logo productId={data?.ClientForm.productId} />}
            </Header>
            <Progress />
            {data && (
              <Outlet
                context={{
                  clientId: data.ClientForm.clientId,
                  formId: data.ClientForm.formId,
                }}
              />
            )}
            {(error || submitError) && <GeneralError />}
          </Root>
        </form>
        <LoadingOverlay loading={loading || submitLoading} />
      </FormProvider>
      {data && <TokenExpiredDialog />}
    </>
  );
};
