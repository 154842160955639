import { css } from '@emotion/react';
import { FormControl, FormHelperText, TextField } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormSection } from '../../features/form';
import { ErrorMessage } from '@hookform/error-message';
import { ClientFormPagePropType } from '../routes';

const getStyles = () => ({
  textfield: css`
    max-width: 220px;
  `,
  errorWrapper: css`
    margin: 0;
  `,
});

const isDeliveryDateInTheFuture = (input: string) => {
  const today = new Date();
  const deliveryDate = new Date(input);
  return deliveryDate > today;
};

const isDeliveryDateToFarInPast = (input: string) => {
  const today = new Date();
  const deliveryDate = new Date(input);
  return today.getFullYear() - deliveryDate.getFullYear() > 3;
};

const validateDeliveryDate = (input: string) => {
  if (isDeliveryDateInTheFuture(input)) return 'deliveryDateTodayOrLater';

  if (isDeliveryDateToFarInPast(input)) return 'deliveryDateTooOld';

  return true;
};
const FORM_ELEMENT_ID = 'deliveryDate';
export const DeliveryDatePage: React.FC<ClientFormPagePropType> = ({
  nextStep,
  previousStep,
}) => {
  const styles = getStyles();

  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext();
  const error = errors[FORM_ELEMENT_ID];

  const isFormValid = () => {
    return trigger(FORM_ELEMENT_ID);
  };
  return (
    <FormSection
      nextStep={nextStep}
      previousStep={previousStep}
      title={t('DELIVERY_DATE_PAGE.title')}
      description={t('DELIVERY_DATE_PAGE.description')}
      infoBoxChildren={t('DELIVERY_DATE_PAGE.hint')}
      isFormValid={isFormValid}
    >
      <FormControl required>
        <Controller
          name={FORM_ELEMENT_ID}
          control={control}
          rules={{
            required: 'deliveryDateRequired',
            validate: validateDeliveryDate,
          }}
          render={({ field }) => {
            return (
              <TextField
                {...field}
                css={styles.textfield}
                InputLabelProps={{ shrink: true }}
                required
                type="date"
                label={t('deliveryDate')}
                error={!!error}
              />
            );
          }}
        />
        <FormHelperText error component="div" css={styles.errorWrapper}>
          <ErrorMessage
            as="div"
            name={FORM_ELEMENT_ID}
            render={({ message }) => (
              <p>{t(`DELIVERY_DATE_PAGE.${message}`)}</p>
            )}
          />
        </FormHelperText>
      </FormControl>
    </FormSection>
  );
};
