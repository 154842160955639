import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Button, FormControl, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { InfoBox } from '../../features/info-box';
import {
  Aside,
  Content,
  Footer,
  Main,
  Subtitle,
  Title,
  Wrapper,
} from '../Layout';
import { ClientFormPagePropType } from '../routes';
import { useStepNavigation } from './useStepNavigation';
import { useTranslation } from 'react-i18next';

export const NotePage: React.FC<ClientFormPagePropType> = ({
  nextStep,
  previousStep,
}) => {
  const { t } = useTranslation();
  const { handleNextStep, handlePreviousStep } = useStepNavigation({
    nextStep,
    previousStep,
  });

  const { control } = useFormContext();

  return (
    <Wrapper>
      <Main>
        <Content>
          <Title>{t('NOTE_PAGE.title')}</Title>
          <Subtitle>{t('NOTE_PAGE.description')}</Subtitle>
          <FormControl>
            <Controller
              name="note"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  multiline
                  minRows={4}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label={t('NOTE_PAGE.inputLabel')}
                ></TextField>
              )}
            />
          </FormControl>
        </Content>
        <Footer>
          <Button
            variant="text"
            onClick={handlePreviousStep}
            startIcon={<KeyboardArrowLeft />}
          >
            {t('previousStep')}
          </Button>
          <Button
            variant="contained"
            onClick={handleNextStep}
            endIcon={<KeyboardArrowRight />}
          >
            {t('nextStep')}
          </Button>
        </Footer>
      </Main>
      <Aside>
        <InfoBox>
          <Box my={1}>
            <Typography variant="body1">
              <strong>{t('hint')}</strong>
            </Typography>
          </Box>
          <Typography variant="body1">{t('NOTE_PAGE.hint')}</Typography>
        </InfoBox>
      </Aside>
    </Wrapper>
  );
};
