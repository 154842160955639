import React from 'react';
import { Header } from '@mineko/mineko-ui';
import { Aside, Content, Main, Root, Subtitle, Title, Wrapper } from './Layout';
import { Logo } from '../features/header';
import { CheckResultDocumentType, useClientFormQuery } from '../api';
import { GeneralError } from '../features/error';

import {
  Divider,
  IconButton,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  css,
} from '@mui/material';
import { Description, Download } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const listItem = css`
  background-color: #eee;
`;

const CheckResultsNotReady = () => {
  return (
    <Content>
      <Title>Die Prüfung ist noch nicht abgeschlossen</Title>
      <Subtitle>Bitte haben Sie noch etwas Geduld</Subtitle>
    </Content>
  );
};

type CheckResultDocumentPropsType = {
  document: CheckResultDocumentType;
};

const CheckResultDocumentItem: React.FC<CheckResultDocumentPropsType> = ({
  document,
}) => {
  const { t } = useTranslation();
  return (
    <ListItem alignItems="center" css={listItem}>
      <ListItemIcon>
        <Description />
      </ListItemIcon>
      <ListItemText primary={t(`CHECK_RESULT.${document.type}`)} />
      <IconButton
        LinkComponent={Link}
        target="_blank"
        href={document.url}
        rel="noreferrer"
        color="primary"
        data-id={`CHECK_RESULT_DOCUMENT.${document.type}`}
      >
        <Download />
      </IconButton>
    </ListItem>
  );
};

export const CheckResultPage: React.FC = () => {
  const { data, error } = useClientFormQuery();
  const productId = data?.ClientForm.productId;
  const checkResultDocuments = data?.ClientForm.checkResultDocuments;
  const { t } = useTranslation();
  return (
    <Root>
      <Header>{productId && <Logo productId={productId} />}</Header>
      <Wrapper>
        {data && (
          <Main>
            {!checkResultDocuments?.length && <CheckResultsNotReady />}
            {checkResultDocuments && (
              <Content>
                <Title>{t('CHECK_RESULT.checkResultReady')}</Title>
                <Subtitle>{t('CHECK_RESULT.downloadCheckResult')}</Subtitle>

                {checkResultDocuments.map((doc) => (
                  <>
                    <CheckResultDocumentItem key={doc.type} document={doc} />
                    <Divider />
                  </>
                ))}
              </Content>
            )}
          </Main>
        )}
        {error && <GeneralError />}
        <Aside />
      </Wrapper>
    </Root>
  );
};
