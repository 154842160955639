import React, { useEffect } from 'react';
import { Aside, Root } from './Layout';
import { Header } from '@mineko/mineko-ui';
import { useTranslation } from 'react-i18next';
import { Container, Stack, Theme, Typography, useTheme } from '@mui/material';
import { SentimentNeutralOutlined } from '@mui/icons-material';
import { useRefreshTokenMutation } from '../api';
import { LoadingButton } from '@mui/lab';
import { GeneralError } from '../features/error';
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

const getStyles = (theme: Theme) => ({
  content: css`
    height: 100%;
    align-items: center;
    justify-content: center;
  `,
  headline: css`
    margin-bottom: ${theme.spacing(2)};
  `,
  icon: css`
    fill: ${theme.palette.primary.light}69;
    height: 180px;
    width: 180px;
  `,
  container: css`
    height: calc(100vh - 70px - 40px);
  `,
  footer: css`
    display: flex;
    flex-direction: row-reverse;
    a {
      margin-right: ${theme.spacing(3)};
    }
  `,
  success: css`
    height: 172px;
  `,
});

export const RefreshToken: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);
  const navigate = useNavigate();

  const [refreshToken, { data, loading, error }] = useRefreshTokenMutation();

  const handleRefreshToken = () => {
    refreshToken();
  };

  useEffect(() => {
    if (data) navigate('./success');
  });

  if (error) return <GeneralError />;

  return (
    <Root>
      <Header>{}</Header>
      <Container css={styles.container}>
        <Stack css={styles.content} spacing={3}>
          <>
            <SentimentNeutralOutlined css={styles.icon} />
            <div>
              <Typography variant="h4" align="center" css={styles.headline}>
                {t('TOKEN_REFRESH_PAGE.title')}
              </Typography>
              <Typography variant="body1" align="center">
                {t('TOKEN_REFRESH_PAGE.description')}
              </Typography>
              <Typography variant="body1" align="center">
                {t('TOKEN_REFRESH_PAGE.callToAction')}
              </Typography>
            </div>
            <LoadingButton
              loading={loading}
              color="primary"
              variant="contained"
              onClick={handleRefreshToken}
            >
              {t('TOKEN_REFRESH_PAGE.requestNewLink')}
            </LoadingButton>
          </>
        </Stack>
      </Container>
      <Aside />
    </Root>
  );
};
