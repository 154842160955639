import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { useStepNavigation } from '../../pages/ClientForm/useStepNavigation';
import {
  Aside,
  Content,
  Footer,
  Main,
  Subtitle,
  Title,
  Wrapper,
} from '../../pages/Layout';
import { InfoBox } from '../info-box';
import { ClientFormPagePropType } from '../../pages';
import { useTranslation } from 'react-i18next';

type FormSectionProps = PropsWithChildren &
  ClientFormPagePropType & {
    title: string;
    description: string;
    isFormValid: () => Promise<boolean>;
    infoBoxChildren: React.ReactNode;
    disableNextButton?: boolean;
  };
export const FormSection: React.FC<FormSectionProps> = ({
  title,
  description,
  children,
  nextStep,
  previousStep,
  isFormValid,
  infoBoxChildren,
  disableNextButton,
}) => {
  const { t } = useTranslation();
  const { handleNextStep, handlePreviousStep } = useStepNavigation({
    nextStep,
    previousStep,
  });

  const handleNextStepClick = async () => {
    const isValid = await isFormValid();
    isValid && handleNextStep();
  };

  return (
    <Wrapper>
      <Main>
        <Content>
          <Title>{title}</Title>
          <Subtitle>{description}</Subtitle>
          {children}
        </Content>
        <Footer>
          <Button
            variant="text"
            onClick={handlePreviousStep}
            startIcon={<KeyboardArrowLeft />}
          >
            {t('previousStep')}
          </Button>
          <Button
            disabled={disableNextButton}
            variant="contained"
            onClick={handleNextStepClick}
            endIcon={<KeyboardArrowRight />}
          >
            {t('nextStep')}
          </Button>
        </Footer>
      </Main>
      <Aside>
        <InfoBox>
          <Box my={1}>
            <Typography variant="body1">
              <strong>{t('hint')}</strong>
            </Typography>
          </Box>
          <Typography variant="body1" component="div">
            {infoBoxChildren}
          </Typography>
        </InfoBox>
      </Aside>
    </Wrapper>
  );
};
