import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import Dropzone from 'react-dropzone';
import { Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { AddAPhoto, CloudOffOutlined, CloudUpload } from '@mui/icons-material';
import { css } from '@emotion/react';
const getStyles = (theme) => ({
    select: css `
    color: ${theme.palette.primary.main};
    text-decoration: underline;
    cursor: pointer;
  `,
    icon: css `
    font-size: 60px;
  `,
    root: css `
    background-color: ${theme.palette.extraColors.background.primaryLight};
    outline: 2px dashed #0000001f;
    outline-offset: -10px;
    width: 100%;
    transition: 0.1s;
  `,
    rootActive: css `
    background-color: ${theme.palette.extraColors.background.primaryDark};
    outline: 2px dashed #0000001f;
    outline-offset: -15px;
    width: 100%;
    transition: 0.1s;
  `,
});
export const UploadArea = ({ AcceptFiles, acceptFilesText, chooseFilesText, dropFilesText, mobileText, onDrop, rejectFilesText, }) => {
    const theme = useTheme();
    const styles = getStyles(theme);
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (_jsx(Dropzone, { onDrop: onDrop, accept: AcceptFiles, children: ({ getRootProps, getInputProps, isDragActive, isDragReject }) => (_jsxs("div", { ...getRootProps(), "data-id": "drop-zone", children: [_jsx("input", { ...getInputProps(), "data-id": "drop-input" }), !mobile ? (_jsx("div", { css: isDragActive ? styles.rootActive : styles.root, children: _jsxs(Stack, { justifyContent: 'center', alignItems: 'center', spacing: 1, padding: 3, children: [isDragReject ? (_jsx(CloudOffOutlined, { css: styles.icon, color: "error" })) : (_jsx(CloudUpload, { css: styles.icon, color: "primary" })), _jsx(Stack, { direction: 'row', spacing: 0.5, children: isDragReject ? (_jsx(Typography, { color: "error", children: rejectFilesText })) : (_jsxs(_Fragment, { children: [_jsx(Typography, { children: dropFilesText }), _jsx(Typography, { css: styles.select, children: chooseFilesText })] })) }), _jsx(Stack, { direction: 'row', spacing: 0.5, children: _jsx(Typography, { color: "GrayText", variant: "caption", children: acceptFilesText }) })] }) })) : (_jsx(Button, { variant: "contained", color: "primary", endIcon: _jsx(AddAPhoto, {}), fullWidth: true, children: mobileText }))] })) }));
};
