import React, { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import { Box, Theme, Typography, useTheme } from '@mui/material';

const CONTENT_TOP_PADDING = '20vh';
const FOOTER_HEIGHT = '100px';
const FOOTER_HEIGHT_MOBILE = '70px';
const getStyles = (theme: Theme) => ({
  root: css`
    height: 100vh;
    display: flex;
    flex-direction: column;
    ${theme.breakpoints.down('md')} {
      min-height: auto;
    }
  `,
  wrapper: css`
    flex: 1;
    display: flex;
    background-color: ${theme.palette.grey[200]};
    ${theme.breakpoints.down('md')} {
      flex-direction: column;
    }
  `,
  main: css`
    flex: 1;
    background-color: ${theme.palette.common.white};
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  `,
  content: css`
    position: relative;
    max-width: 640px;
    width: 100%;
    padding: 0 ${theme.spacing(3)};
    padding-top: ${CONTENT_TOP_PADDING};
    padding-bottom: ${FOOTER_HEIGHT};
    display: flex;
    flex-direction: column;
    ${theme.breakpoints.down('md')} {
      padding-top: calc(${theme.mixins.toolbar.height} + ${theme.spacing(4)});
      padding-bottom: ${theme.spacing(2)};
    }
  `,
  footer: css`
    max-width: 640px;
    width: 100%;
    height: ${FOOTER_HEIGHT};
    align-items: flex-start;
    padding: ${theme.spacing(2)} ${theme.spacing(3)};
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    background: white;
    z-index: ${theme.zIndex.appBar};

    :before {
      content: '';
      position: absolute;
      top: 0;
      left: ${theme.spacing(3)};
      right: ${theme.spacing(3)};
      height: 1px;
      background-color: ${theme.palette.grey[300]}cc;
    }

    ${theme.breakpoints.down('md')} {
      height: ${FOOTER_HEIGHT_MOBILE};
    }
  `,
  asideFooter: css`
    position: absolute;
    bottom: ${theme.spacing(2)};
    right: ${theme.spacing(2)};
    ${theme.breakpoints.down('md')} {
      bottom: calc(${FOOTER_HEIGHT_MOBILE} + ${theme.spacing(2)});
    }
  `,
  aside: css`
    flex: 0 0 40%;
    padding: 0 ${theme.spacing(3)};
    padding-top: ${CONTENT_TOP_PADDING};
    position: relative;
    ${theme.breakpoints.down('md')} {
      background-color: ${theme.palette.common.white};
      padding: 0;
      padding-top: ${theme.spacing(4)};
      padding-bottom: calc(${FOOTER_HEIGHT_MOBILE} + ${theme.spacing(8)});
      justify-content: center;
      display: flex;
    }
  `,
  title: css`
    position: absolute;
    transform: translateY(-100%);
    padding-bottom: ${theme.spacing(2)};
    padding-right: ${theme.spacing(3)};
    ${theme.breakpoints.down('md')} {
      font-size: 1.5rem;
    }
  `,
});

export const Root: React.FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  return <div css={styles.root}>{children}</div>;
};

export const Wrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  return <div css={styles.wrapper}>{children}</div>;
};

export const Main: React.FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  return <div css={styles.main}>{children}</div>;
};

export const Content: React.FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  return <div css={styles.content}>{children}</div>;
};

export const Footer: React.FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  return <div css={styles.footer}>{children}</div>;
};

export const Aside: React.FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  return (
    <div css={styles.aside}>
      {children}
      <div css={styles.asideFooter}>
        <a
          href="https://www.mineko.de/impressum"
          target="_blank"
          rel="noreferrer"
        >
          Impressum
        </a>
      </div>
    </div>
  );
};

export const Title = ({ children }: PropsWithChildren) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  return (
    <Typography variant="h4" css={styles.title}>
      {children}
    </Typography>
  );
};

export const Subtitle = ({ children }: PropsWithChildren) => {
  return (
    <Box paddingRight={4} paddingBottom={4}>
      <Typography color="GrayText" variant="body1" component="div">
        {children}
      </Typography>
    </Box>
  );
};
