import { Alert, Box, Container } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const GeneralError: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Box p={4}>
        <Alert severity="error">{t('generalError')}</Alert>
      </Box>
    </Container>
  );
};
