import { ThumbUp } from '@mui/icons-material';
import { Avatar, Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoBox } from '../../features/info-box';
import { Aside, Content, Main, Subtitle, Title, Wrapper } from '../Layout';
import { ClientFormPagePropType } from '../routes';
import { css } from '@emotion/react';
import { Theme } from '@mineko/mineko-ui/theme/mineko.theme';

const getStyles = (theme: Theme) => ({
  avatar: css`
    color: ${theme.palette.extraColors.background.primaryLight};
    width: 25vh;
    max-width: 100%;
    height: auto;
  `,
});

export const SuccessPage: React.FC<ClientFormPagePropType> = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <Wrapper>
      <Main>
        <Content>
          <Title>{t('SUCCESS_PAGE.title')}</Title>
          <Subtitle>{t('SUCCESS_PAGE.description')}</Subtitle>
          <p>
            <ThumbUp css={styles.avatar} />
          </p>
        </Content>
      </Main>
      <Aside>
        <InfoBox>
          <Box my={1}>
            <Typography variant="body1">
              <strong>{t('hint')}</strong>
            </Typography>
          </Box>
          <Typography variant="body1">{t('SUCCESS_PAGE.hint')}</Typography>
        </InfoBox>
      </Aside>
    </Wrapper>
  );
};
