{
  "pleaseWait": "Bitte Warten",
  "deliveryDate": "Zustellungsdatum",
  "rentalContract": "Mietvertrag",
  "operationalStatement": "Betriebskostenabrechnung",
  "heatingStatement": "Heizkostenabrechnung",
  "note": "Allgemeine Hinweise",
  "skipHeatingStatement": "keine separate Heizkostenabrechnung erhalten",
  "hint": "Kurzer Tipp:",
  "nextStep": "Weiter",
  "previousStep": "Zurück",
  "WELCOME_PAGE": {
    "stepDeliveryDate": "Zustellungsdatum angeben",
    "stepUploadDocuments": "Unterlagen hochladen",
    "stepStartAndWait": "Prüfung starten und zurücklehnen",
    "title": "Ihre Betriebskostenabrechnung prüfen lassen",
    "greating": "Willkommen {{firstName}} {{lastName}}.",
    "referenceNumber": "Ihre Referenznummer lautet \"{{reference}}\".",
    "description": "Übermitteln Sie in wenigen einfachen Schritten Ihre Unterlagen zur Prüfung Ihrer Betriebskostenabrechnung.",
    "hint": "Halten Sie Ihren Mietvertrag, sowie Ihre Betriebskostenabrechnung und (falls vorhanden) Ihre Heizkostenabrechnung bereit.",
    "startNow": "Loslegen"
  },
  "DELIVERY_DATE_PAGE": {
    "title": "Zustellungsdatum angeben",
    "description": "Wann haben Sie die Betriebskostenabrechnung von Ihrem Vermieter erhalten?",
    "hint": "Das Zustelldatum bezeichnet das Datum, an dem die Abrechnung Ihnen übergeben oder in den Briefkasten zugestellt wurde.",
    "deliveryDateRequired": "Bitte geben Sie das Zustellungsdatum an.",
    "deliveryDateTodayOrLater": "Das eingebene Datum darf nicht in der Zukunft liegen.",
    "deliveryDateTooOld": "Das Jahr darf nicht älter als 3 Jahre sein."
  },
  "RENTAL_CONTRACT_PAGE": {
    "title": "Mietvertrag hochladen",
    "description": "Bitte laden Sie jetzt Ihren vollständigen Mietvertrag inklusive aller Anhänge und Nachträge hoch.",
    "rentalContractRequired": "Bitte laden Sie Ihren Mietvertrag hoch.",
    "hint": "Haben Sie weitere Hinweise zu Ihrer Abrechnung, die für eine korrekte Prüfung Ihrer Betriebskostenabrechnung wesentlich sein könnten? Gibt es Informationen zu Sachverhalten, die Ihr Mietverhältnis beeinflussen?"
  },
  "OPERATIONAL_STATEMENT_PAGE": {
    "title": "Betriebskostenabrechnung hochladen",
    "description": "Bitte laden Sie jetzt Ihre Betriebskostenabrechnung vollständig inklusive aller Anhänge hoch.",
    "operationalStatementRequired": "Bitte laden Sie Ihre Betriebskostenabrechnung hoch."
  },
  "HEATING_STATEMENT_PAGE": {
    "title": "Heizkostenabrechnung hochladen",
    "description": "Bitte laden Sie jetzt – falls vorhanden – Ihre Heizkostenabrechnung vollständig inklusive aller Anhänge hoch.",
    "skipHeatingStatement": "Ich habe keine separate Heizkostenabrechnung erhalten.",
    "operationalStatementRequired": "Bitte laden Sie Ihre Betriebskostenabrechnung hoch.",
    "heatingStatementRequired": "Bitte laden Sie Ihre Heizkostenabrechnung hoch oder bestätigen Sie, dass Sie keine erhalten haben."
  },
  "NOTE_PAGE": {
    "title": "Allgemeine Hinweise",
    "inputLabel": "Hinweise",
    "description": "Teilen Sie uns hier mit, was Sie noch für wichtig erachten.",
    "hint": "Haben Sie weitere Hinweise zu Ihrer Abrechnung, die für eine korrekte Prüfung Ihrer Betriebskostenabrechnung wesentlich sein könnten? Gibt es Informationen zu Sachverhalten, die Ihr Mietverhältnis beeinflussen?"
  },
  "SUMMARY_PAGE": {
    "title": "Übersicht Ihrer Angaben",
    "description": "Bitte überprüfen Sie, ob alle Angaben richtig sind und Sie alle Dokumente Vollständig hochgeladen haben.",
    "confirm": "Bestätigen und Auftrag einreichen",
    "acceptAgb": "Ich habe die <1>AGB</1> und <3>Datenschutzerklärung</3> von Mineko GmbH gelesen und akzeptiere diese.",
    "acceptAgbRequired": "Bitte akzeptieren Sie die AGB und Datenschutzerklärung.",
    "hint": "Durch Klicken von <1></1> können Sie Ihre Angaben noch einmal anpassen."
  },
  "SUCCESS_PAGE": {
    "title": "Vielen Dank",
    "description": "Wir haben Ihre Daten entgegen genommen und beginnen nun mit der Prüfung Ihrer Betriebskostenabrechnung.",
    "hint": "Sollten wir weitere Fragen zu Ihrer Betriebskostenabrechnung haben, werden wir uns schnellstmöglich bei Ihnen melden."
  },
  "TOKEN_REFRESH_PAGE": {
    "title": "Link abgelaufen!",
    "description": "Ihr Link für die Prüfung Ihrer Betriebskostenabrechnung ist leider abgelaufen.",
    "callToAction": "Aber keine Sorge. Ein neuer Link kann hier angefordert werden.",
    "requestNewLink": "Jetzt neuen Link anfordern",
    "titleSuccess": "Neuer Link angefordert!",
    "descriptionSuccess": "Wir haben Ihnen einen neuen Link per E-Mail zugeschickt.",
    "callToActionSuccess": "Sie können dieses Browserfenster nun schließen."
  },
  "CHECK_RESULT": {
    "checkResultReady": "Das Ergebnis Ihrer Prüfung steht zum Download bereit",
    "downloadCheckResult": "Bitte laden Sie den Prüfbericht und das Widerspruchsschreiben zur weiteren Verwendung herunter.",
    "CHECK_REPORT": "Prüfbericht",
    "CONTRADICTION_LETTER": "Widerspruchsschreiben",
    "EXTRAJUDICIAL_LETTER": "Außergerichtliches Mahnschreiben"
  },
  "TOKEN_EXPIRED_DIALOG": {
    "title": "Dein Link ist abgelaufen",
    "description": "Dein Link ist leider abgelaufen. Bitte fordere einen neuen Link an.",
    "confirm": "Neuen Link anfordern",
    "cancel": "Abbrechen"
  },
  "documentUploadHint": "Achten Sie darauf, dass Ihre Fotos oder Scans folgende Bedingungen erfüllen: <1><2>genügend Licht</2><2>keine unscharfen Stellen</2><2>eine Seite pro Foto</2><2>Seiten im Hochformat</2></1>",
  "generalError": "Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal oder kontaktieren Sie unseren Support."
}
