import { useNavigate } from 'react-router-dom';
import { CLIENT_FORM_PAGES_MAP, ClientFormPagePropType } from '../routes';

export const useStepNavigation = ({
  nextStep,
  previousStep,
}: ClientFormPagePropType) => {
  const navigate = useNavigate();
  const handleNextStep = () => {
    nextStep &&
      navigate({
        pathname: nextStep,
        search: location.search,
      });
  };
  const handlePreviousStep = () => {
    previousStep &&
      navigate({
        pathname: previousStep,
        search: location.search,
      });
  };

  const goToStep = (step: keyof typeof CLIENT_FORM_PAGES_MAP) => {
    if (step === 'index') return;
    const path = CLIENT_FORM_PAGES_MAP[step].path;
    path &&
      navigate({
        pathname: `../${path}`,
        search: location.search,
      });
  };

  return {
    handleNextStep,
    handlePreviousStep,
    goToStep,
  };
};
