import React from 'react';
import { useConfig } from '@mineko/react-toolkit';
import { css } from '@emotion/react';
import { Theme, useTheme } from '@mui/material';
const getStyles = (theme: Theme) => ({
  img: css`
    height: 100%;
  `,
  root: css`
    padding: ${theme.spacing(1, 0)};
    display: flex;
    align-items: center;
    height: 100%;
  `,
});

type LogoPropType = {
  productId: string;
};
export const Logo: React.FC<LogoPropType> = ({ productId }) => {
  const config = useConfig();
  const theme = useTheme();
  const styles = getStyles(theme);

  const brokerLogoUrl = `${config?.APP.REACT_APP_STATIC_BASE_URL}${productId}.png`;

  return (
    <div css={styles.root}>
      <img data-id="broker-logo" src={brokerLogoUrl} css={styles.img} />
    </div>
  );
};
