import { createSlice } from '@reduxjs/toolkit';

type UploadStateType = {
  uploadingFiles: string[];
};

const initialState: UploadStateType = { uploadingFiles: [] };

const reducers = {
  uploadFileRequired: (state: UploadStateType, action: { payload: string }) => {
    state.uploadingFiles.push(action.payload);
  },
  uploadFileCompleted: (
    state: UploadStateType,
    action: { payload: string },
  ) => {
    const completedFileId = action.payload;
    state.uploadingFiles = state.uploadingFiles.filter(
      (id) => id !== completedFileId,
    );
  },
};

export const { actions, reducer } = createSlice({
  name: 'upload',
  reducers,
  initialState,
});
