import { FormControl, FormHelperText } from '@mui/material';

import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FormSection } from '../../features/form';
import {
  Upload,
  selectIsDocumentUploadInProgress,
} from '../../features/upload';
import { FormStateType, useClientFormContext } from '../ClientFormPage';
import { ClientFormPagePropType } from '../routes';

const DOCUMENT_TYPE = 'RENTAL_CONTRACT';
const FORM_ELEMENT_ID = 'documents.RENTAL_CONTRACT';
export const RentalContractPage: React.FC<ClientFormPagePropType> = ({
  nextStep,
  previousStep,
}) => {
  const { t } = useTranslation();
  const isUploadInProgress = useSelector(selectIsDocumentUploadInProgress);
  const { trigger } = useFormContext<FormStateType>();
  const { formId, clientId } = useClientFormContext();
  const isFormValid = () => {
    if (isUploadInProgress) return Promise.resolve(false);
    return trigger(FORM_ELEMENT_ID);
  };
  return (
    <FormSection
      nextStep={nextStep}
      previousStep={previousStep}
      title={t('RENTAL_CONTRACT_PAGE.title')}
      description={t('RENTAL_CONTRACT_PAGE.description')}
      infoBoxChildren={
        <Trans i18nKey="documentUploadHint">
          Please make sure that the uploaded documents have:
          <ul>
            <li>enough light</li>
            <li>no blurry parts</li>
            <li>one page per picture</li>
            <li>pages in portrait orientation</li>
          </ul>
        </Trans>
      }
      isFormValid={isFormValid}
      disableNextButton={isUploadInProgress}
    >
      <FormControl data-id="upload-rentalContract">
        <Upload
          documentType={DOCUMENT_TYPE}
          clientId={clientId}
          formId={formId}
          rules={{
            required: 'rentalContractRequired',
          }}
        />
        <FormHelperText error component="div">
          <ErrorMessage
            name={`${FORM_ELEMENT_ID}.root`}
            render={({ message }) => (
              <p>{t(`RENTAL_CONTRACT_PAGE.${message}`)}</p>
            )}
            as="div"
          />
        </FormHelperText>
      </FormControl>
    </FormSection>
  );
};
