import {
  DocumentUpload,
  DocumentUploadPropType,
  UploadedFileType,
} from '@mineko/mineko-ui/features';
import React, { useCallback } from 'react';
import axios from 'axios';
import { DocumentTypeEnumType, usePrepareUploadMutation } from '../../api';
import {
  UseFieldArrayProps,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { FormStateType } from '../../pages';
import { useDispatch } from 'react-redux';
import { actions } from './state';

const documentUploadTexts = {
  acceptFiles: 'PDF, JPG oder PNG',
  chooseFiles: 'auswählen',
  dropFiles: 'Datei hierher ziehen oder',
  rejectFiles: 'Datei wird nicht unterstützt',
  mobileText: 'Datei auswählen',
};

type UploadPropType = {
  documentType: DocumentTypeEnumType;
  clientId: string;
  formId: string;
  rules: UseFieldArrayProps['rules'];
};
export const Upload: React.FC<UploadPropType> = ({
  documentType,
  clientId,
  formId,
  rules,
}) => {
  const { control } = useFormContext<FormStateType>();
  const dispatch = useDispatch();
  const [prepareUpload] = usePrepareUploadMutation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `documents.${documentType}`,
    rules,
  });

  const initialFiles = fields;
  const handleUploadFile: DocumentUploadPropType['onUploadFile'] = useCallback(
    ({ file, onUploadProgress, url }) => {
      console.log('Try uploading file');
      return axios.put(url, file, {
        onUploadProgress: ({ progress }) =>
          progress && onUploadProgress({ progress }),
      });
    },
    [],
  );

  const handleFileUploadRequired = useCallback(
    (fileName: string) => {
      return prepareUpload({
        variables: {
          input: {
            clientId,
            fileName,
            documentType,
            formId,
          },
        },
      }).then((result) => {
        if (!result.data?.PrepareUpload?.uploadUrl)
          throw new Error('No upload url returned');
        dispatch(actions.uploadFileRequired(result.data.PrepareUpload.fileId));
        return {
          url: result.data.PrepareUpload.uploadUrl,
          id: result.data.PrepareUpload.fileId,
        };
      });
    },
    [clientId, documentType, formId, prepareUpload, dispatch],
  );

  const handleFileUploadSuccess = useCallback(
    (file: UploadedFileType) => {
      append(file);
      dispatch(actions.uploadFileCompleted(file.id));
    },
    [append, dispatch],
  );

  const handleFileDelete = useCallback(
    (id: string) => {
      remove(fields.findIndex((field) => field.id === id));
    },
    [fields, remove],
  );
  return (
    <DocumentUpload
      onUploadFile={handleUploadFile}
      onFileUploadRequired={handleFileUploadRequired}
      onFileUploadSuccess={handleFileUploadSuccess}
      onFileDelete={handleFileDelete}
      texts={documentUploadTexts}
      initialFiles={initialFiles}
    />
  );
};
