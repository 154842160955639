import { css } from '@emotion/react';
import { SvgIconComponent } from '@mui/icons-material';
import { Avatar, Theme, Typography, useTheme } from '@mui/material';
import React from 'react';

const AVATAR_ICON_SIZE = 54;
export const STEP_SPACING = 8.75;

const getStyles = (theme: Theme) => ({
  step: css`
    position: relative;
    padding-left: ${theme.spacing(3)};
    display: flex;
    align-items: center;
    &:not(:first-of-type)&:before {
      content: '';
      position: absolute;
      left: calc(${theme.spacing(3)} + ${AVATAR_ICON_SIZE / 2}px);
      bottom: 100%;
      margin-bottom: 10px;
      width: 2px;
      background-color: ${theme.palette.grey[300]};
      height: calc(${theme.spacing(STEP_SPACING)} - 20px);
    }
  `,
  avatar: css`
    background-color: ${theme.palette.primary.main};
    width: ${AVATAR_ICON_SIZE}px;
    height: ${AVATAR_ICON_SIZE}px;
    margin-right: ${theme.spacing(2)};
  `,
});

export const Step: React.FC<{ Icon: SvgIconComponent; label: string }> = ({
  Icon,
  label,
}) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  return (
    <div css={styles.step}>
      <Avatar css={styles.avatar} variant="rounded">
        <Icon fontSize="medium" />
      </Avatar>
      <Typography variant="body1">
        <strong>{label}</strong>
      </Typography>
    </div>
  );
};
